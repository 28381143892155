<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    :class="direction"
  >
    <path
      fill="#5ed7f2"
      fill-rule="evenodd"
      d="M39.702 54.08l3.472 3.738L66.857 35.85 43.174 13.886l-3.472 3.737L56.607 33.3H5.143v5.1h51.464z"
    />
  </svg>
</template>

<script>
export default {
  props: ["direction"]
};
</script>

<style lang="scss" scoped>
.w {
  transform: rotate(180deg);
}
.n {
  transform: rotate(-90deg);
}
.s {
  transform: rotate(90deg);
}
.nw {
  transform: rotate(-45deg);
}
.se {
  transform: rotate(45deg);
}
.sw {
  transform: rotate(135deg);
}
.ne {
  transform: rotate(-135deg);
}
</style>

