<template>
  <div id="app">
    <top-bar :old-style="true" />
    <router-view />
    <bottom-bar />
  </div>
</template>

<script>
// import { loadYmap } from "vue-yandex-maps";

import TopBar from "@/components/TopBar";
import BottomBar from "@/components/BottomBar";

export default {
  components: { TopBar, BottomBar },
  mounted() {
    console.log(process.env.NODE_ENV);
    // loadYmap();
    window.idleTimer = null;
    window.idleWait = process.env.NODE_ENV == "development" ? 1000000 : 45000;

    const listener = () => {
      clearTimeout(window.idleTimer);

      window.idleTimer = setTimeout(() => {
        if (this.$route.name == "StoryScreen") listener();
        else this.goToStartScreen();
      }, window.idleWait);
    };

    listener();
    document.querySelector("body").addEventListener("mousemove", listener); // its realy needed?
    document.querySelector("body").addEventListener("touchstart", listener);
    document.querySelector("body").addEventListener("keydown", listener);
  },

  methods: {
    goToStartScreen() {
      if (this.$route.path !== "/") this.$router.push({ path: "/" });
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/main.scss";
</style>