import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// global components
import HtmlParser from "@/components/Wrappers/HtmlParser";
Vue.component("txt", HtmlParser);

//swiper
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
Vue.use(VueAwesomeSwiper /* { default global options } */);

//moment js
const moment = require("moment");
require("moment/locale/ru");
Vue.use(require("vue-moment"), {
  moment
});

// ymap
// import YmapPlugin from "@/components/vue-yandex-maps";
import YmapPlugin from "vue-yandex-maps";
Vue.use(YmapPlugin, {
  apiKey: "",
  lang: "ru_RU",
  coordorder: "latlong",
  version: "2.1"
});

// api methods
import API from "@/api/api";
window.api = new API();

// config
Vue.config.productionTip = false;

import methods from "@/methods";
Vue.mixin({
  methods,
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
