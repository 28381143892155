const getDefaultState = () => {
  return {
    stories: []
  };
};

const state = getDefaultState();

const actions = {
  fetchStoriesList: ({ commit, dispatch, state }) => {
    return api.getStories().then(res => {
      console.log(res.data);
      commit("setStories", res.data);
      // dispatch("SET_SPINNER", false, { root: true });
    });
  }
};

const getters = {
  stories: state => state.stories
};

const mutations = {
  setStories(state, res) {
    state.stories = res;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
