const getDefaultState = () => {
  return {
    coord: null,
    city: null,
    address: null,
    zoom: null
  };
};

const state = getDefaultState();

const actions = {
  fetchPanel: ({ commit }, id) => {
    api.getPanel(id).then(res => {
      commit("setPanel", res.data[0]);
    });
  }
};

const getters = {
  coord: state => state.coord,
  zoom: state => state.zoom,
  cityId: state => state.city
};

const mutations = {
  setPanel(state, res) {
    state.coord = res.location;
    state.city = res.city_id;
    state.address = res.address;
    state.zoom = res.zoom ? res.zoom : 10;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
