import axios from "axios";
import config from "./config";

var show_log = true;
var res_count = 1000;

// axios(config);
class API {
  // ----------------------
  // weather
  // ----------------------
  getWeather() {
    return axios
      .get(
        "https://api.openweathermap.org/data/2.5/weather?units=metric&lat=68.98&lon=33.09&appid=8a9b4c75a1373ba213555dd6788d99ba"
      )
      .then(res => {
        return res.data;
      });
  }

  // ----------------------
  // stories
  // ----------------------

  // ----------------------
  // city
  // ----------------------
  getCity() {
    return this.get('/cities')
  }

  // ----------------------
  // panel
  // ----------------------
  getPanel(id) {
    return this.get(`/panels/?panel_id=${id}`);
  }

  // ----------------------
  // stories
  // ----------------------

  getStories() {
    return this.get(`/stories?count=${res_count}`);
  }
  getStory(id) {
    return this.get(`/stories/${id}`);
  }

  // ----------------------
  // places
  // ----------------------
  getPlaces() {
    return this.get("/places")
  }

  getSlidersPlaces(type) {
    return this.get(`/sliders?position=${type}`).then(result => {
      return this.get(`/sliders/${result.data[0].id}`)
    });
  }

  // ----------------------
  // objects
  // ----------------------
  getObjectTypes() {
    return this.get(`/types?count=${res_count}`);
  }
  getObjects(type, page, count = res_count, city) {
    const getCity = city ? `&city_id=${city}` : "";
    const getType = type && type != 0 ? `&type_id=${type}` : "";

    return this.get(`/places?page=${page}&count=${count}${getType}${getCity}&show=id,title,image,type,location,images&resolution=small`);
  }
  getObjectsByType(city, page, count = res_count) {
    const getCity = city ? `&city_id=${city}` : "";
    return this.get(`/types?page=${page}&places=${count}${getCity}`);
  }
  getNearObjects(id) {
    return this.get(`/places/?near=${id}&count=4`);
  }
  getObject(id, size) {
    const imageSize = size ? `?resolution=${size}` : '';
    return this.get(`/places/${id}${imageSize}`);
  }

  getCategoriesTypes() {
    return this.get('/categories/places');
  }

  getPlacesById(categoryId, cityId, counter, page) {
    const count = counter ? `&count=${counter}` : '';
    const currentPage = page ? `&page=${page}` : '';
    const places = categoryId ? `&category_id=${categoryId}` : '';
    const city = cityId ? `&city_id=${cityId}` : '';

    return this.get(`/places?${places}${city}${count}${currentPage}`);
  }

  // ----------------------
  // events
  // ----------------------
  getEventCity(cityId, date, counter, page) {
    const currentDate = `&started_at=${date}`;
    const city = cityId ? `&city_id=${cityId}` : '';
    const count = counter ? `&count=${counter}` : '';
    const currentPage = page ? `&page=${page}` : '';

    return this.get(`/events?${city}${currentDate}&sort=created_at${count}${currentPage}`);
  }
  getAllEvents(date, counter, page) {
    const count = counter ? `&count=${counter}` : '';
    const currentPage = page ? `&page=${page}` : '';
    return this.get(`/events?started_at=${date}&sort=created_at${count}${currentPage}&resolution=medium`);
  }

  getEvents(dates, count = res_count, calend = 0, badge = 0) {
    count = count ? count : res_count;
    const started = dates && dates.length ? `&started_at=${dates[0]}` : "";
    const stoped = dates && dates.length == 2 ? `&stopped_at=${dates[1]}` : "";
    const calendar = calend ? `&calendar=${calend}` : "";
    const badge_id = badge ? `&badge_id=${badge}` : "";

    if (dates)
      return this.get(
        `/content/event/?${started}${stoped}&count=${count}${calendar}${badge_id}`
      );
    else
      return this.get(`/content/event/?count=${count}${calendar}${badge_id}`);
  }
  getEvent(id) {
    return this.get(`/content/event/${id}`);
  }
  // ----------------------
  // badges
  // ----------------------
  getBadges() {
    return this.get(`/categories/events`);
  }

  // ----------------------
  // facts
  // ----------------------
  getFacts(city, count = res_count, resolution='medium') {
    const getCity = city ? `&city_id=${city}` : "";
    return this.get(`/content/article/?resolution=${resolution}&count=${count}${getCity}`);
  }
  getFact(id) {
    return this.get(`/content/article/${id}`);
  }

  // ----------------------
  // general API interfaces
  // ----------------------

  // axios get interface
  get(url) {
    return new Promise((resolve, reject) => {
      if (show_log)
        console.log(
          "%c " + new Date().toISOString() + " | API request, GET: " + url,
          "background: #222; color: #bada55"
        );
      axios
        .get(`${url}`, config)
        .then(
          response => {
            resolve(response.data);
          },
          err => {
            reject(err);
          }
        )
        .catch(error => {
          reject(error);
        });
    });
  }

  // axios post interface
  post(url, data) {
    return new Promise((resolve, reject) => {
      if (show_log)
        console.log(
          "%c " + new Date().toISOString() + " | API request, POST: " + url,
          "background: #222; color: #bada55"
        );
      axios
        .post(url, data, config)
        .then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        )
        .catch(error => {
          reject(error);
        });
    });
  }

  // axios delete interface
  delete(url) {
    return new Promise((resolve, reject) => {
      if (show_log)
        console.log(
          "%c " + new Date().toISOString() + " | API request, DELETE: " + url,
          "background: #222; color: #bada55"
        );
      axios
        .delete(url, config)
        .then(
          response => {
            resolve(response.data);
          },
          err => {
            reject(err);
          }
        )
        .catch(error => {
          reject(error);
        });
    });
  }
}
export default API;
