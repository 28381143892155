const getDefaultState = () => {
  return {
    events: null,
    selectedEvent: null,
    isLoading: false,
  };
};

const state = getDefaultState();

const actions = {
  fetchEvents: ({ commit, dispatch, state }, dates) => {
    commit("setEvents", []);

    api.getEvents(dates).then(res => {
      commit("setEvents", res.data);
    });
  },

  setSelectedEvent: ({ commit, dispatch, state }, data) => {
    commit("setSelectedEvent", data);
  }
};

const getters = {
  events: state => state.events,
  selectedEvent: state => state.selectedEvent,
  isLoadingEvents: state => state.isLoading,
};

const mutations = {
  setEvents(state, res) {
    state.events = res;
  },
  setSelectedEvent(state, res) {
    state.selectedEvent = res;
  },
  setIsLoadingEvents(state, res) {
    state.isLoading = res;
  },
};

export default {
  state,
  actions,
  getters,
  mutations
};
