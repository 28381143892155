import Vue from "vue";
import Vuex from "vuex";

import panel from "./modules/panel";
import stories from "./modules/stories";
import objects from "./modules/objects";
import events from "./modules/events";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    panel,
    stories,
    objects,
    events
  }
});
