<template>
  <div class="topbar-main">
    <p class="title">Гид путеводитель: Мурманск</p>
    <div class="info">

      <div class="datetime">
        <div class="fz-85">{{ realTime | moment("LT") }}</div>
        <div class="fz-35">{{ realTime | moment("D MMMM") }} • {{ realTime | moment("dddd") }}</div>
      </div>

      <weather />
    </div>

    <div class="nav">
      <div class="place">
        <compass class="arrow" :direction="arrow_1" />
        <div class="name">{{title_1}}</div>
        <div class="duration">{{duration_1}}</div>
      </div>
      <div class="place">
        <compass class="arrow" :direction="arrow_2" />
        <div class="name">{{title_2}}</div>
        <div class="duration">{{duration_2}}</div>
      </div>
    </div>
    <img class="gerb" src="../assets/img/gerb.png" />
  </div>
</template>

<script>
import Compass from "@/components/Icons/Compass";
import Weather from "@/components/Parts/Weather"

export default {
  name: "TopBar",

  props: {
    oldStyle: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Compass,
    Weather
  },

  data() {
    return {
      realTime: this.$moment(),
      events: [],
      calendar: null,
    };
  },

  computed: {
    title_1() {
      return typeof this.$route.query.title_1 == "object"
        ? this.$route.query.title_1[0]
        : this.$route.query.title_1;
    },

    title_2() {
      return typeof this.$route.query.title_2 == "object"
        ? this.$route.query.title_2[0]
        : this.$route.query.title_2;
    },

    arrow_1() {
      return typeof this.$route.query.arrow_1 == "object"
        ? this.$route.query.arrow_1[0]
        : this.$route.query.arrow_1;
    },

    arrow_2() {
      return typeof this.$route.query.arrow_2 == "object"
        ? this.$route.query.arrow_2[0]
        : this.$route.query.arrow_2;
    },

    duration_1() {
      return typeof this.$route.query.duration_1 == "object"
        ? this.$route.query.duration_1[0]
        : this.$route.query.duration_1;
    },

    duration_2() {
      return typeof this.$route.query.duration_2 == "object"
        ? this.$route.query.duration_2[0]
        : this.$route.query.duration_2;
    },

  },

  async mounted() {
    await this.fetchEvents();
    await this.fetchCalendar();
    setInterval(() => {
      this.fetchEvents();
      this.fetchCalendar();
    }, 60 * 60 * 1000); // one hour


    const urlParams = new URLSearchParams(window.location.search);
    this.$store.dispatch("fetchPanel", urlParams.get('panel_id'));

    setInterval(() => {
      this.realTime = this.$moment();
    }, 10 * 1000); // ten seconds
  },

  methods: {
    fetchEvents() {
      api.getEvents([this.$moment().format("YYYY-MM-DD")], 10).then(res => {
        this.events = [];
        res.data.forEach(event => {
          this.events.push(
            this.$moment(new Date(event.started_at * 1000)).format("DD.MM LT") +
              " " +
              event.title
          );
        });
      });
    },

    fetchCalendar() {
      api
        .getEvents(
          [
            this.$moment().format("YYYY-MM-DD"),
            this.$moment().format("YYYY-MM-DD")
          ],
          1,
          1
        )
        .then(res => {
          this.calendar = res.data[0];
        });
    },
  }
};



import router from "@/router";

router.beforeEach((to, from, next) => {
  //console.log(to, from);
  if (!to.query.title_1) {
    to.query.title_1 = from.query.title_1 ? from.query.title_1 : null;
    to.query.title_2 = from.query.title_2 ? from.query.title_2 : null;
    to.query.arrow_1 = from.query.arrow_1 ? from.query.arrow_1 : null;
    to.query.arrow_2 = from.query.arrow_2 ? from.query.arrow_2 : null;
    to.query.duration_1 = from.query.duration_1 ? from.query.duration_1 : null;
    to.query.duration_2 = from.query.duration_2 ? from.query.duration_2 : null;
    to.query.panel_id = from.query.panel_id ? from.query.panel_id : null;
    next({ path: to.path, query: to.query });
  }
  next();
});
</script>

<style lang="scss">
@import "@/scss/utils/_variables.scss";

.fz-85 {
  padding-bottom: 8px;
  font-size: 85px;
  line-height: 84px;
  letter-spacing: -1.36px;
}

.fz-35 {
  font-size: 35px;
  color: rgba(#fff, 0.7);
}

.topbar-main {
  position: relative;
  background: $color_dark;
  height: 560px;
  width: 100%;
  font-family: $ObjectSans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;

  .title {
    padding: 23px 42px;
    background-color: $color_grey_2a;
    font-size: 35px;
    line-height: 36px;
    color: rgba(#fff, 0.97);
  }

  .info {
    width: 100%;
    height: 176px;
    background: $color_grey_2;
    padding: 24px 32px;
    display: flex;

    .event {
      & > div {
        display: flex;
        height: 128px;
      }

      .img {
        width: 128px;
        height: 128px;
        background: #000;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .tag {
          position: relative;
          margin-right: auto;
          margin-bottom: 13px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $color_yellow_dark_2;
          border-radius: 10px;

          span {
            margin: auto 24px;
            font-size: 20px;
            font-weight: 500;
            line-height: unset;
            letter-spacing: -0.2px;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            padding-top: 4px;
          }
        }

        .name {
          max-width: 250px;
          font-size: 24px;
          line-height: 1.15;
          letter-spacing: 0.1px;
          color: rgba(#fff, 0.7);
        }
      }
    }

    .datetime {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 32px;
      border-right: 1px solid #49494B;
      text-align: left;

      .time {
        padding-bottom: 8px;

        span {
          font-size: 72px;
        }
      }
    }
  }

  .nav {
    width: 100%;
    height: 304px;

    .place {
      position: relative;
      padding: 40px 32px;
      display: flex;
      align-items: center;
      color: rgba(#fff, 0.97);

      &:first-child::after {
        content: "";
        width: 904px;
        height: 4px;
        background: $color_grey_2;
        position: absolute;
        top: 150px;
        left: 144px;
      }

      .arrow {
        margin-right: 40px;
      }

      .name {
        width: 752px;
        font-size: 44px;
        line-height: 1.09;
        letter-spacing: -0.5px;
      }

      .duration {
        width: 152px;
        text-align: right;
        font-size: 35px;
        line-height: 1.03;
        letter-spacing: -0.5px;
        font-weight: 500;
      }
    }
  }

  .gerb {
    position: absolute;
    top: 30px;
    right: 40px;
    width: 151px;
    height: 207px;
  }
}
</style>
