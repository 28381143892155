<template>
  <div class="weather">
    <div class="weather-degrees">
      <div class="fz-85"> {{ weather.real }}° </div>
      <img class="weather-icon"
        :src="`/img/weather/${weather.icon}.png`">
    </div>
    <div class="weather-degrees fz-35">
      min {{ weather.min }}° max {{ weather.max }}° 
    </div>
  </div>
</template>

<script>
export default {
  name: "Weather",
  data() {
    return {
      weather: {
        real: null,
        icon: null,
        min: null,
        max: null
      },
    }
  },

  mounted() {
    this.fetchWeather()
  },

  methods: {
    fetchWeather() {
      api.getWeather().then(res => {
        this.weather.real = Math.round(res.main.temp);
        this.weather.icon = res.weather[0].icon;
        this.weather.max = res.main.temp_max;
        this.weather.min = res.main.temp_min;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.fz-85 {
  padding-bottom: 8px;
  font-size: 85px;
  line-height: 84px;
  letter-spacing: -1.36px;
}

.fz-35 {
  font-size: 35px;
  color: rgba(#fff, 0.7);
}

.weather {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 32px;

  .weather-degrees {
    display: flex;
  }

  .weather-icon {
    width: 69px;
    height: 69px;
    margin-left: 33px;
  }
}
</style>