export default {
  $date(startedAt, stoppedAt, createdAt) {
    let dateString,
      start = new Date(startedAt * 1000) || new Date(createdAt * 1000),
      stop = new Date(stoppedAt * 1000);
    if (
      this.$moment(start).format("YYYY-MM-DD") ===
      this.$moment(stop).format("YYYY-MM-DD")
    ) {
      dateString = this.$moment(start).format("DD.MM.YYYY") + " ";
      if (
        this.$moment(start).format("LT") !== this.$moment(stop).format("LT")
      ) {
        dateString +=
          this.$moment(start).format("LT") +
          " — " +
          this.$moment(stop).format("LT");
      }
      else {
        dateString += this.$moment(start).format("LT");
      }
    } else {
      if (
        this.$moment(start).format("MM-DD") ===
        this.$moment(stop).format("MM-DD")
      ) {
        dateString =
          this.$moment(start).format("DD MMMM LT") +
          " — " +
          this.$moment(stop).format("LT");
      } else if (
        this.$moment(start).format("LT") === this.$moment(stop).format("LT")
      ) {
        dateString = this.$moment(start).format("DD.MM.YYYY") + " — " +  this.$moment(stop).format("DD.MM.YYYY")
      } else {
        dateString =
          this.$moment(start).format("D MMMM LT") +
          " — " +
          this.$moment(stop).format("D MMMM LT");
      }
    }

    return dateString;
  },

  $removeLinkTags(str) {
    if ((str === null) || (str === '')) {
      return false;
    } else {
      str = str.toString();
    }

    console.log(str)

    return str.replace( /<(?:a\b[^>]*>|a>)/ig, '');
  }
}
